export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114')
];

export const server_loads = [0,2,15,16,17,8,9,11,13,14];

export const dictionary = {
		"/(app)": [~18,[2],[3]],
		"/(app)/accept-terms-and-conditions": [~22,[2],[3]],
		"/(app)/account": [23,[2,4],[3]],
		"/(app)/account/auction-invitations/[invitationId]": [~24,[2,4],[3]],
		"/(app)/account/bids": [~25,[2,4],[3]],
		"/(app)/account/billing": [~26,[2,4],[3]],
		"/(app)/account/billing/invoices/[invoiceId]": [~27,[2,4],[3]],
		"/(app)/account/email-settings": [~28,[2,4],[3]],
		"/(app)/account/invitations/[invitationId]": [~29,[2,4],[3]],
		"/(app)/account/notifications": [30,[2,4],[3]],
		"/(app)/account/notifications/[notificationId]": [31,[2,4],[3]],
		"/(app)/account/profile": [~32,[2,4],[3]],
		"/(app)/account/security": [~33,[2,4],[3]],
		"/(app)/account/watchlist": [~34,[2,4],[3]],
		"/admin": [90,[15]],
		"/admin/auctions": [~91,[15]],
		"/admin/blog": [~92,[15]],
		"/admin/blog/new": [~94,[15]],
		"/admin/blog/[postId]": [~93,[15]],
		"/admin/pages": [~95,[15]],
		"/admin/pages/new": [~97,[15]],
		"/admin/pages/[pageId]": [~96,[15]],
		"/admin/stores": [~98,[15]],
		"/admin/stores/[storeId]": [~99,[15,16]],
		"/admin/stores/[storeId]/auctions/[auctionId]": [100,[15,16,17]],
		"/admin/stores/[storeId]/auctions/[auctionId]/billing": [~101,[15,16,17]],
		"/admin/stores/[storeId]/auctions/[auctionId]/billing/[invoiceId]": [~102,[15,16,17]],
		"/admin/stores/[storeId]/auctions/[auctionId]/lots": [~103,[15,16,17]],
		"/admin/stores/[storeId]/auctions/[auctionId]/lots/new": [~105,[15,16,17]],
		"/admin/stores/[storeId]/auctions/[auctionId]/lots/[itemId]": [~104,[15,16,17]],
		"/admin/stores/[storeId]/auctions/[auctionId]/metrics": [~106,[15,16,17]],
		"/admin/stores/[storeId]/auctions/[auctionId]/pickup": [~107,[15,16,17]],
		"/admin/stores/[storeId]/auctions/[auctionId]/review-and-publish": [108,[15,16,17]],
		"/admin/stores/[storeId]/auctions/[auctionId]/settings": [~109,[15,16,17]],
		"/admin/stores/[storeId]/auctions/[auctionId]/settlement": [~110,[15,16,17]],
		"/admin/stores/[storeId]/auctions/[auctionId]/setup": [~111,[15,16,17]],
		"/admin/testing": [~112,[15]],
		"/admin/users": [~113,[15]],
		"/admin/users/[userId]": [~114,[15]],
		"/(app)/become-seller": [~35],
		"/(app)/blog": [~36,[2,5],[3]],
		"/(app)/blog/[postSlug]": [~37,[2,5],[3]],
		"/(app)/categories/[categorySlug]": [~38,[2],[3]],
		"/(app)/email-settings/[notificationsId]": [~39],
		"/(auth)/email-verification": [~84],
		"/(auth)/login": [~85],
		"/(auth)/otp-verification": [~86],
		"/(app)/pages": [40,[2,6],[3]],
		"/(app)/pages/about": [42,[2,6],[3]],
		"/(app)/pages/buyer-terms-and-conditions": [43,[2,6],[3]],
		"/(app)/pages/contact-us": [44,[2,6],[3]],
		"/(app)/pages/cookie-use-policy": [45,[2,6],[3]],
		"/(app)/pages/frequently-asked-questions": [46,[2,6],[3]],
		"/(app)/pages/pricing": [47,[2,6],[3]],
		"/(app)/pages/privacy-policy": [48,[2,6],[3]],
		"/(app)/pages/seller-terms-and-conditions": [49,[2,6],[3]],
		"/(app)/pages/[...pageUrlName]": [~41,[2,6],[3]],
		"/(auth)/password-reset-email": [~88],
		"/(auth)/password-reset": [87],
		"/(app)/preview/[auctionCode]": [~50,[8]],
		"/(app)/preview/[auctionCode]/[itemCode]": [~51,[8]],
		"/(auth)/register": [~89],
		"/(app)/search": [~52,[2],[3]],
		"/(app)/sellers": [~53],
		"/(app)/sellers/new": [~77],
		"/(app)/sellers/[storeId]": [54,[9]],
		"/(app)/sellers/[storeId]/account": [55,[9,10]],
		"/(app)/sellers/[storeId]/account/banking": [~56,[9,10]],
		"/(app)/sellers/[storeId]/account/storefront": [~57,[9,10]],
		"/(app)/sellers/[storeId]/account/subscription": [~58,[9,10]],
		"/(app)/sellers/[storeId]/auctions": [~59,[9]],
		"/(app)/sellers/[storeId]/auctions/new": [~74,[9]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]": [~60,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/bidding-taxes": [~61,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/billing": [~62,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/billing/[invoiceId]": [~63,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/cancelation": [~64,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/lot-gallery": [~65,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/lot-gallery/new": [~67,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/lot-gallery/[itemId]": [~66,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/members": [~68,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/metrics": [~69,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/pickup-times": [~71,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/pickup": [~70,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/review-and-publish": [~72,[9,11]],
		"/(app)/sellers/[storeId]/auctions/[auctionId]/setup": [~73,[9,11]],
		"/(app)/sellers/[storeId]/emails": [~75,[9]],
		"/(app)/sellers/[storeId]/team": [~76,[9,12]],
		"/(app)/stores/[storeCode]": [~78,[2,13],[3]],
		"/(app)/stores/[storeCode]/auctions": [~79,[2,13],[3]],
		"/(app)/stores/[storeCode]/auctions/[auctionCode]": [~80,[2,13,14],[3]],
		"/(app)/stores/[storeCode]/auctions/[auctionCode]/search": [~82,[2,13,14],[3]],
		"/(app)/stores/[storeCode]/auctions/[auctionCode]/[itemCode]": [~81,[2,13,14],[3]],
		"/(app)/stores/[storeCode]/search": [~83,[2,13],[3]],
		"/(app)/[countryCode]": [~19,[2],[3]],
		"/(app)/[countryCode]/[stateCode]": [~20,[2],[3]],
		"/(app)/[countryCode]/[stateCode]/[regionCode]": [~21,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';